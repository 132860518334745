@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ref: https://stackoverflow.com/a/31523871 */
input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

.leaflet-control {
  z-index: 0 !important;
}
.leaflet-pane {
  z-index: 0 !important;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 0 !important;
}

.ag-header-icon.ag-header-cell-menu-button {
  opacity: 1 !important;
}

.ag-root-wrapper {
  border-radius: 0.5rem;
  --ag-header-background-color: rgb(249 250 251);
  --ag-border-color: rgba(0, 0, 0, 0.05);
  --ag-data-color: rgb(107 114 128);
  box-shadow:
    rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
}

.ag-header {
  border-bottom: 1px solid rgb(209 213 219) !important;
}

.ag-theme-alpine {
  --ag-row-height: 50px !important;
}

.ag-row-odd {
  background-color: var(--ag-even-row-background-color) !important;
}
